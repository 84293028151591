
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        









































































































































































































































































































.breadcrumb {
  font-size: 1.4rem;
  padding-right: 50%;
  margin-block: 0;
  padding-block: 50px;

  @media screen and (max-width: 767px) {
    padding-right: 0;
    padding-bottom: 0;
  }
}

.page-header-image {
  max-width: 70px;
  @media screen and (max-width: 767px) {
    margin-top: 4rem;
  }
}

.page-header {
  margin-top: 1rem;

  &__title {
    display: flex;
    flex-flow: row wrap;
    width: auto;

    h1.main-title {
      display: block;

      @include mq(m) {
        display: inline-block;
      }
    }

    span.subtitle {
      display: inline-block;
      margin-left: 0;
      font-size: 1.4rem;
      @include mq(m) {
        margin-left: 1.5rem;
      }
    }

    .tips {
      margin-left: auto;
    }
  }

  &__actions {
    flex-direction: row;
    align-items: flex-start;
    display: flex;
    margin-top: 1em;

    @media screen and (max-width: 767px) {
      width: 100%;
      display: block;
      gap: unset;
      align-items: stretch;
      margin-top: 3rem;

      a {
        width: 100%;
      }
    }
  }

  .btn-area {
    a {
      height: 62px;
    }

    @media screen and (max-width: 767px) {
      top: 20px;
      margin-bottom: 3rem;
      height: auto;

      a {
        width: 100%;
      }
    }

    .add-index {
      display: inline-flex;
    }
  }

  .export {
    margin-left: auto;
  }
}

.table {
  display: table;
  border-collapse: collapse;
}

.table__th {
  @extend %fw-normal;
  // prettier-ignore
  @include fluid(font-size, (xxs: 9px, m: 11px));

  padding: 0 0.5rem 2rem;
  color: $c-orange;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.table__td {
  padding: 1.5rem 0.5rem;

  tr:not(:last-child) & {
    border-bottom: 1px solid $c-gray-light;
  }
}
